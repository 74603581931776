import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { Link, Route, Routes, useMatch, Navigate } from "react-router-dom";
import MyCalendar from "../Calendar/calendar";
import "./scheduler.scss";
import logo from "../../assets/socialoha_logo.png";
import WritePost from "../WritePost/writepost";
import AIContent from "../AIContent/ideate"; // Import AIContent component
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import Modal from "react-modal";
import defaultImage from "../../assets/placeholder_image.png";
import { Analytics } from "@mui/icons-material";
import StepperIdeate from "../AIContent/stepper_ideate";

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com";
// const backendUrl = 'http://localhost:8080'

const Scheduler = () => {
  const { currentUser } = useAuth();
  const match = useMatch("/scheduler/*");
  const [userName, setUserName] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`,
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            setUserName(user.name);
            setUserId(user.user_id); // Set the user ID here
          } else {
            console.error("User not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchProfilePicture = async (userId) => {
      try {
        const response = await axios.get(
          `${backendUrl}/api/profile_picture/${userId}`,
        );
        setProfilePicture(response.data.profile_picture_url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    const checkUserAuthorization = async (userId) => {
      try {
        const authResponse = await axios.get(
          `${backendUrl}/queries/has_user_authorization/${userId}`,
        );
        if (!authResponse.data.exists) {
          setIsModalOpen(true); // Open the modal if user is not authorized
        }
      } catch (error) {
        console.error("Error checking user authorization:", error);
      }
    };

    fetchUserData().then(() => {
      if (userId) {
        fetchProfilePicture(userId);
        checkUserAuthorization(userId);
      }
    });
  }, [currentUser, userId]);

  const handleAuthRedirect = () => {
    if (userId) {
      window.location.href = `https://socialoha-server-0b454a3e2f86.herokuapp.com/authorization/auth?user_id=${userId}`;
    } else {
      console.error("User ID is not available");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="scheduler-container">
      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Connect to Facebook"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Connect to Facebook</h2>
        <p>
          If you wish to use the functionalities of our website, connect to your
          Facebook account:
        </p>
        <button onClick={handleAuthRedirect}>Connect to Facebook</button>
        <button onClick={closeModal}>Cancel</button>
      </Modal> */}
      {/* <aside className="sidebar">
                
                <nav>
                    <ul>
                        <li><Link to="/dash"><i className="fas fa-tachometer-alt"></i> Dashboard</Link></li>
                        <li><Link to={`${match.pathnameBase}/ideate`}><i className="fas fa-th"></i> Ideate</Link></li>
                        <li><Link to={`${match.pathnameBase}/calendar`}><i className="fas fa-calendar-alt"></i> Scheduler</Link></li>
                        <li><Link to="/scheduler"><i className="fas fa-chart-bar"></i> Analytics</Link></li>
                        <li><Link to="/aicontent"><i className="fas fa-cogs"></i> AI Content</Link></li>
                        <li><Link to={`${match.pathnameBase}/writepost`}><i className="fas fa-pen"></i> Write a Post</Link></li>
                        <li><Link to="/SignUp"><i className="fas fa-sign-out-alt"></i> Logout</Link></li>
                    </ul>
                </nav>
            </aside> */}
      <main className="main-content">
        {/* <header className="header">
                    <nav className="top-nav">
                    <div class="logo-container">
                    <img src={logo} alt="Logo" className="logo-image" />
                    <span className="logo-text">Soci<span className="logo-highlight">Al</span>oha</span>
                </div>
                        <div className="user-info">
                            <ReactCountryFlag countryCode="GB" svg className="language-icon" />
                            <div className="user-details">
                                <img src={profilePicture || defaultImage} alt="User" className="user-icon" />
                                <div className="user-text">
                                    <span>{userName}</span>
                                    <span>Admin</span>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header> */}
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={<Navigate to={`${match.pathnameBase}/calendar`} />}
            />
            <Route path="calendar" element={<MyCalendar />} />
            <Route path="writepost" element={<WritePost />} />
            <Route path="ideate" element={<StepperIdeate />} />
            <Route path="analytics" element={<Analytics />} />
          </Routes>
        </div>
      </main>
    </div>
  );
};

export default Scheduler;
