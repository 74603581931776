import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext"; // Adjust the path as needed
import ChangePasswordModal from "./ChangePasswordModal"; // Import the password modal component

const backendUrl = "https://socialoha-server-0b454a3e2f86.herokuapp.com"; // Update as needed
// const backendUrl = 'http://localhost:8080'

export default function AccountPage() {
  const { currentUser } = useAuth();
  const [accountInfo, setAccountInfo] = useState({
    name: "",
    email: "",
    brand_name: "",
    categories: "",
    target_customer: "",
    tone_voice: "",
    content_type: "",
  });
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        try {
          // Fetch user details
          const userResponse = await axios.get(
            `${backendUrl}/queries/data_user`
          );
          const users = userResponse.data;
          const user = users.find((item) => item.email === currentUser.email);

          if (user) {
            const userId = user.user_id;

            // Fetch business info
            const businessResponse = await axios.get(
              `${backendUrl}/queries/data_business_info_health/${userId}`);
            const businessData = businessResponse.data;
            const userData = businessData.find(
              (item) => item.user_id === userId
            );

            if (userData) {
              setAccountInfo((prev) => ({
                ...prev,
                name: user.name || "",
                email: user.email || "",
                brand_name: userData.brand_name || "",
                categories: userData.categories || "",
                target_customer: userData.target_customer || "",
                tone_voice: userData.tone_voice || "",
                content_type: userData.content_type || "",
              }));
            } else {
              console.log(
                "No business information found for the provided user ID."
              );
            }
          } else {
            console.log("No user found with the provided email.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleInputChange = (e) => {
    setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setIsEditing(false); // Disable editing mode

    const payload = {
      email: accountInfo.email,
      brand_name: accountInfo.brand_name,
      categories: accountInfo.categories,
      target_customer: accountInfo.target_customer,
      tone_voice: accountInfo.tone_voice,
      content_type: accountInfo.content_type,
    };

    try {
      const response = await axios.put(
        `${backendUrl}/queries/update_business_info_health`,
        payload
      );

      if (response.status === 200) {
        alert("Business information updated successfully.");
      } else {
        alert("Error updating business information.");
      }
    } catch (error) {
      console.error("Error updating business information:", error);
      alert("An error occurred while updating the business information.");
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Re-fetch data from the API to reset form
  };

  return (
    <div
      className="container mx-auto p-4"
      style={{ backgroundColor: "#f7f6f2" }}
    >
      <div className="bg-white shadow-lg rounded-lg p-4 max-w-4xl ml-6">
        <h2 className="text-2xl font-bold mb-2">Account Information</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-1">
            Name
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded-md text-gray-700 focus:ring focus:ring-teal-300"
            value={accountInfo.name}
            disabled={!isEditing}
            name="name"
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-1">
            Email
          </label>
          <input
            type="email"
            className="w-full px-3 py-2 border rounded-md text-gray-700 focus:ring focus:ring-teal-300"
            value={accountInfo.email}
            disabled={!isEditing}
            name="email"
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-4">
          <button
            className="text-teal-600 hover:text-teal-800 font-semibold text-xs p-1 border rounded-md shadow-sm"
            onClick={() => setIsPasswordModalOpen(true)}
          >
            Change Password
          </button>
        </div>
        <ChangePasswordModal
          isOpen={isPasswordModalOpen}
          onClose={() => setIsPasswordModalOpen(false)}
        />
        <h2 className="text-2xl font-bold mt-4 mb-2">Questionnaire Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[
            { label: "Brand Name", name: "brand_name" },
            { label: "Categories", name: "categories" },
            { label: "Target Customer", name: "target_customer" },
            { label: "Tone or Voice", name: "tone_voice" },
            { label: "Content Type", name: "content_type" },
          ].map(({ label, name }) => (
            <div className="mb-4" key={name}>
              <label className="block text-gray-700 text-sm font-bold mb-1">
                {label}
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded-md text-gray-700 focus:ring focus:ring-teal-300"
                value={accountInfo[name]}
                disabled={!isEditing}
                name={name}
                onChange={handleInputChange}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          {isEditing ? (
            <>
              <button
                className="bg-gray-500 text-white px-3 py-1 rounded-md hover:bg-gray-700"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-teal-500 text-white px-3 py-1 rounded-md hover:bg-teal-600"
                onClick={handleSave}
              >
                Save Changes
              </button>
            </>
          ) : (
            <button
              className="bg-teal-500 text-white px-3 py-1 rounded-md hover:bg-teal-600"
              onClick={() => setIsEditing(true)}
            >
              Edit Information
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
